import styled from '@emotion/styled';
import Section from '../atoms/Section';
import SectionHeader from '../atoms/SectionHeader';
import Subsection from '../atoms/Subsection';
import Text from '../atoms/Text';
import bracelets from '../assets/bracelets.png';

const PermanentJewelrySection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 40px;

  @media (max-width: 1025px) {
    #services-image {
      order: 2;
    }
    #services-content {
      order: 1;
    }
    p {
      font-size: 1.2rem;
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  p {
    text-align: left;
    font-size: 1.2rem;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: unset;
    margin-right: unset;
  }
  width: 85%;
  justify-content: space-between;
`;

const SubtitleContainer = styled.div`
  display: flex;
  p {
    text-align: center;
    font-size: 1.3rem;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: unset;
    margin-right: unset;
  }
  justify-content: space-between;
  align-content: center;
`;

const PermanentJewelryDiscliamer = styled.span`
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
  color: #3a668c;
  padding: 0.1rem;
  font-size: 0.9rem;
  line-height: 1.1rem;
  margin-top: 0;
  width: 85%;
  text-align: left;

  @media (max-width: 1025px) {
    text-align: left;
    font-size: 0.9rem;
  }
`;

const PermanentJewelryImage = styled.img`
  @media (max-width: 1025px) {
    height: 100%;
    width: 100%;
  }
  width: 80%;
`;

const PermanentJewelry = () => {
  return (
    <PermanentJewelrySection id='permanent-jewelry'>
      <SectionHeader>PERMANENT JEWELRY</SectionHeader>
      <Text style={{ fontSize: '0.8rem', lineHeight: '0.8rem', marginTop: 0 }}>
        Now offering Permanent Jewelry!
      </Text>
      <Section>
        <Subsection id='services-content'>
          <SubtitleContainer>
            <Text>GOLD FILLED</Text>
          </SubtitleContainer>
          <TextContainer>
            <Text>Bracelet</Text>
            <Text>$55</Text>
          </TextContainer>
          <TextContainer>
            <Text>Necklace</Text>
            <Text>$85</Text>
          </TextContainer>
          <TextContainer>
            <Text>Ring</Text>
            <Text>$25</Text>
          </TextContainer>
          <TextContainer>
            <Text>Anklet</Text>
            <Text>$65</Text>
          </TextContainer>
          <SubtitleContainer>
            <Text>SILVER</Text>
          </SubtitleContainer>
          <TextContainer>
            <Text>Bracelet</Text>
            <Text>$45</Text>
          </TextContainer>
          <TextContainer>
            <Text>Necklace</Text>
            <Text>$75</Text>
          </TextContainer>
          <TextContainer>
            <Text>Ring</Text>
            <Text>$20</Text>
          </TextContainer>
          <TextContainer>
            <Text>Anklet</Text>
            <Text>$55</Text>
          </TextContainer>
          <PermanentJewelryDiscliamer>
            • Children age 2-12: $10 off each price listed above
          </PermanentJewelryDiscliamer>
        </Subsection>
        <Subsection id='services-image'>
          <PermanentJewelryImage
            src={bracelets}
            alt='piercing-locations-image'
          />
        </Subsection>
      </Section>
    </PermanentJewelrySection>
  );
};

export default PermanentJewelry;
