import styled from '@emotion/styled';

const MainLogo = styled.img`
  @media (max-width: 1025px) {
    height: 100%;
    width: 100%;
  }
  height: 80%;
  width: 80%;
`;

export default MainLogo;
