import styled from '@emotion/styled';
import Section from '../atoms/Section';
import SectionHeader from '../atoms/SectionHeader';
import Subsection from '../atoms/Subsection';
import Text from '../atoms/Text';
import earstack from '../assets/ear-stack.png';

const PoliciesSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (max-width: 1025px) {
    #policies-image {
      order: 2;
    }
    #policies-content {
      order: 1;
    }
    p {
      font-size: 1.2rem;
    }
  }
`;

const TextWrapper = styled.div`
  width: 50%;

  p {
    text-align: left;
  }

  @media (max-width: 1025px) {
    width: 85%;
    p {
      font-size: 1.2rem;
    }
  }
`;

const PoliciesImage = styled.img`
  @media (max-width: 1025px) {
    height: 100%;
    width: 100%;
  }
  width: 80%;
`;

//•

const Policies = () => {
  return (
    <PoliciesSection id='policies'>
      <SectionHeader>POLICIES</SectionHeader>
      <Text style={{ fontSize: '0.8rem', lineHeight: '0.8rem', marginTop: 0 }}>
        Effective June 1 2022
      </Text>
      <Section>
        <Subsection id='policies-image'>
          <PoliciesImage src={earstack} alt='earstack' />
        </Subsection>
        <Subsection id='policies-content'>
          <TextWrapper style={{ marginBottom: '2rem', width: '80%' }}>
            <Text>$20 deposit due upon scheduling an appointment</Text>
            <Text>Deposit goes towards total Appointment cost</Text>
            <Text>All deposits are non refundable</Text>
            <Text>
              Deposit is forfeited if appointment is canceled or rescheduled
            </Text>
            <Text>
              Booking by appointment only, otherwise a $45 walk-in fee may be
              applied
            </Text>
            <Text>
              My time as an artist is valuable, and I appreciate you respecting
              me and my policies. I look forward to providing you with my
              services!
            </Text>
          </TextWrapper>
        </Subsection>
      </Section>
    </PoliciesSection>
  );
};

export default Policies;
