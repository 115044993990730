import styled from '@emotion/styled';
import MainLogo from '../atoms/MainLogo';
import Section from '../atoms/Section';
import SectionHeader from '../atoms/SectionHeader';
import Subsection from '../atoms/Subsection';
import Text from '../atoms/Text';
import instagramLogo from '../assets/instagram-logo.png';
import emily from '../assets/emily-headshot.png';

const AboutSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  p {
    text-align: left;
  }

  @media (max-width: 1025px) {
    #about-image {
      order: 2;
    }
    #about-content {
      order: 1;
    }
  }
`;

const InstaLogo = styled.img`
  height: 3vmax;
`;

const InstaWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1025px) {
    p {
      font-size: 1.2rem;
    }
  }
`;

const InstaLink = styled.a`
  margin-left: 1rem;
  margin-bottom: 0.5rem;
`;

const About = () => {
  return (
    <AboutSection id='about'>
      <SectionHeader>ABOUT</SectionHeader>
      <Section>
        <Subsection id='about-content'>
          <Text
            style={{ width: '80%', lineHeight: '2rem', fontSize: '1.2rem' }}
          >
            Hey, I’m Emily! Welcome to my space✨ Let me introduce myself! I’m a
            Cache Valley local, I love the outdoors, Culver’s custard, road
            biking, snuggling my babies, working out, RomComs, and baking yummy
            treats. Chances are you’ll catch me in my leggings, chacos, a tee
            and a cute ear stack. That’s my thing! We keep it casual and cute
            over here. I’m excited to meet you! Let's get stacking💛
          </Text>
          <InstaWrapper>
            <Text style={{ marginTop: '0.5rem' }}>Visit me on Instagram</Text>
            <InstaLink
              href='https://instagram.com/adornedbyemily'
              target='_blank'
              rel='noopener noreferrer'
            >
              <InstaLogo src={instagramLogo} alt='instagram-logo' />
            </InstaLink>
          </InstaWrapper>
        </Subsection>
        <Subsection id='about-image'>
          <MainLogo
            style={{ height: '100%' }}
            src={emily}
            alt='emily-headshot'
          />
        </Subsection>
      </Section>
    </AboutSection>
  );
};

export default About;
